import { Component, DestroyRef, Inject, OnInit } from '@angular/core';
import { filter } from 'rxjs';
import {
  AuthState,
  SetEnvironment,
  UserState,
} from '@gea/digital-ui-lib';
import { Store } from '@ngxs/store';

import {
  ENVIRONMENT_CONFIG,
  EnvironmentConfiguration,
} from '../environments/models/environment.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';

@Component({
  selector: 'addcool-app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  readonly APP_TITLE = 'Insightpartner DairyPowder';

  tokenReady = false;
  userReady = false;
  noAuth = false;
  userCountry = 'US';
  currentLanguage = '';
  loggedOutPageReady = false;

  selectedTabIndex = 0;
  constructor(
    private store: Store,
    private destroyed: DestroyRef,
    private router: Router,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration,
  ) {}

  ngOnInit(): void {
    this.store
      .select(UserState.loggedOutPageReady)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((ready) => {
        this.loggedOutPageReady = !!ready;
      });
    this.store
      .select(UserState.authState)
      .pipe(takeUntilDestroyed(this.destroyed))
      .subscribe((authState) => {
        if (authState === AuthState.LogoutRequested) {
          this.tokenReady = false;
          this.loggedOutPageReady = false;
        }
      });
    // This is the official syntax of ngxs
    // eslint-disable-next-line @typescript-eslint/unbound-method
    this.store
      .select(UserState.user)
      .pipe(
        takeUntilDestroyed(this.destroyed),
        filter((user) => !!user.email),
      )
      .subscribe((user) => {
        console.log('User', user);
        this.tokenReady = true;
        if (user?.country) {
          this.userCountry = user.country;
        }
        this.userReady = true;
      });
      
    if (this.environment.name) {
      this.store.dispatch(new SetEnvironment(this.environment.name));
    }
  }

  addCookielawScriptTag() {
    const cookielawId = this.environment.cookielawId;
    if (cookielawId) {
      const script = document.createElement('script');
      script.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';
      script.type = 'text/javascript';
      script.setAttribute('data-domain-script', cookielawId);
      const head = document.head;
      const firstScriptTag = head.querySelector('script');
      if (firstScriptTag) {
        head.insertBefore(script, firstScriptTag);
      } else {
        head.appendChild(script);
      }
    }
  }

  openDataprivacy() {
    window.open(
      this.environment.storageURL +
        'terms/1/' +
        this.userCountry +
        '/' +
        this.currentLanguage.split('-')[0].toUpperCase() +
        '/DataPrivacy.pdf',
      '_blank',
    );
  }

  openTermsAndConditions() {
    window.open(
      this.environment.storageURL +
        'terms/1/' +
        this.userCountry +
        '/' +
        this.currentLanguage.split('-')[0].toUpperCase() +
        '/TermsAndConditions.pdf',
      '_blank',
    );
  }

  openImprint() {
    window.open(
      '../../assets/documents/Imprint' + this.currentLanguage + '.pdf',
      '_blank',
    );
  }

  openCookieSettings() {
    // this syntax is necessary here to access onetrust
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
    window.OneTrust.ToggleInfoDisplay();
  }

  getRouterUrl(): string {

    return this.router.url;

  }
}
