<div *ngIf="errorString">
    {{errorString}}
</div>
<div class="spinner" *ngIf="loading">
    <p-progressSpinner animationDuration="2s" strokeWidth="4">
    </p-progressSpinner>
</div>
<div class="container">
    <div class="header" style="display: flex;">
        Evaporator
    </div>
    <div class="header-buttons">
        <button mat-button class="header-button" [routerLink]="['/dashboard/notifications']" [ngClass]="{'active': activeSection === 'notifications'}">Notifications</button>
        <button mat-button class="header-button" [routerLink]="['/dashboard/machineryStatus']" [ngClass]="{'active': activeSection === 'machineryStatus'}">Status</button>
    </div>
    <div class="list-container" *ngIf="activeSection === 'notifications'">
        <div *ngFor="let notification of notifications" class="list-item" [routerLink]="['/notification', notification.id]">
            <div class="notification-machine-name">{{ notification.machineName }}</div>
            <div style="display: flex;">
                <div style="display: flex;" class="warning-chip">
                        <img src="assets/icons/16px_warning-sign.svg" style="margin-right: 10px;">
                    <div style="color: #692C01;">
                        Attention
                    </div>
                    <div style="font-weight: 400; margin-left: 16px">
                        {{ notification.timestamp | timeAgo }}
                    </div>
                </div>
            </div>
            <div class="notification-title">
                {{ notification.title}}
            </div>
        </div>
    </div>
    <div *ngIf="activeSection === 'machineryStatus'" class="machine-overview">
        <div *ngFor="let machine of machines" class="machine-list-item" [routerLink]="['/notifications', machine.id]">
            <div class="machine-item">
                <div style="display: flex; align-items: center;">{{ machine.name }}</div>
                <div class="align-right">
                    <mat-icon *ngIf="machine.status === 0" class="status-icon" style="color: #15BD5E;">circle</mat-icon>
                    <mat-icon *ngIf="machine.status > 0" class="status-icon" style="color: #FC4B53;">circle</mat-icon>
                </div>
            </div>
        </div>
    </div>

    <div class="footer">
        <button mat-button class="footer-button" [routerLink]="['/about']">App About</button>
    </div>
</div>