import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENVIRONMENT_CONFIG, EnvironmentConfiguration } from '../../environments/models/environment.model';
import { Machine } from '../models/machine';
import { Notification } from '../models/notification';

@Injectable({
  providedIn: 'root'
})
export class MachineService {
  baseUrl = "";
  constructor(
    private httpClient: HttpClient,
    @Inject(ENVIRONMENT_CONFIG) public environment: EnvironmentConfiguration
  ) {
    this.baseUrl = this.environment.serviceCall.baseURL!;
    this.baseUrl = this.baseUrl.slice(-1) === '/' ? this.baseUrl : this.baseUrl + '/';
    this.baseUrl = `${this.baseUrl}machine`;
  }

  getMachines() {
    return this.httpClient.get<Machine[]>(this.baseUrl);
  }

  getMachineWithNotifications(machineId: number){
    return this.httpClient.get<Machine>(`${this.baseUrl}/notifications/${machineId}`);
  }
}
